<template>
  <div>个人信息管理组件</div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">

</style>

