<template>
    <div class="welcome">
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>
.welcome{
    width:100%;
    height: 100%;
    background:url('../../../assets/images/jzbg.png');
    background-size:100% 100%;
}
</style>