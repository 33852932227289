import { render, staticRenderFns } from "./menuClass.vue?vue&type=template&id=013a6812&scoped=true&"
import script from "./menuClass.vue?vue&type=script&lang=js&"
export * from "./menuClass.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "013a6812",
  null
  
)

export default component.exports