import { render, staticRenderFns } from "./ProcessInfo.vue?vue&type=template&id=ab36470e&scoped=true&"
import script from "./ProcessInfo.vue?vue&type=script&lang=js&"
export * from "./ProcessInfo.vue?vue&type=script&lang=js&"
import style0 from "./ProcessInfo.vue?vue&type=style&index=0&id=ab36470e&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab36470e",
  null
  
)

export default component.exports