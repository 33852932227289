<template>
  <div>行政区统计组件</div>
</template>

<script>
export default {}
</script>

<style scoped lang="less">
</style>

