<template>
  <div>产业统计组件</div>
</template>

<script>
export default {}
</script>

<style scoped lang="less">
</style>

