<template>
  <div class="qyForm">
    <el-card class="box-card">
  <div slot="header" class="clearfix">
    <span class="title">企业信息管理</span>
    <el-button style="float: right; padding: 3px 0" type="text"></el-button>
  </div>
 <div class="leftcard">
    <el-form label-width="100px" class="formqy"  :model="businessInfo">
      <el-form-item label="企业名称">
        <el-input v-model="businessInfo.qymc" ></el-input>
      </el-form-item>
      <el-form-item label="企业负责人">
        <el-input v-model="businessInfo.fzr"></el-input>
      </el-form-item>
      <el-form-item label="负责人手机号">
        <el-input v-model="businessInfo.fzrtel"></el-input>
      </el-form-item>
       <el-form-item label="企业信用代码">
        <el-input v-model="businessInfo.xydm"></el-input>
      </el-form-item>
      <el-form-item label="企业地址">
        <el-input v-model="businessInfo.addr"></el-input>
      </el-form-item>
  
      <el-form-item label="主营业务">
        <el-input v-model="businessInfo.zyyw"></el-input>
      </el-form-item>
      <el-form-item label="主营产品">
        <el-input v-model="businessInfo.zycp"></el-input>
      </el-form-item>
      <el-form-item label="经营规模">
        <el-input v-model="businessInfo.jygm"></el-input>
      </el-form-item>
      <el-form-item label="企业简介">
        <el-input type="textarea" v-model="businessInfo.dwjj"></el-input>
      </el-form-item>
      
    </el-form>
    <el-button type="primary" @click="submitqyInfo">提交修改</el-button>
     </div>
     <div class="rightarea">
       <div class="block">
    <span class="demonstration">企业营业执照</span>
    <el-image :src="src"  style="width: 20vw; height: 10vw"></el-image>
  </div>
  <div class="block">
    <span class="demonstration">身份证件</span>
    <el-image :src="src" style="width: 20vw; height: 10vw">
      <div slot="placeholder" class="image-slot">
        加载中<span class="dot">...</span>
      </div>
    </el-image>
  </div>
  </div>
</el-card>
    
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data(){
    return{
        businessInfo:{
          qymc:'',
          fzr:'',
          fzrtel:'',
          addr:'',
          qylx:'',
          zyyw:'',
          zycp:'',
          jygm:'',
          dwjj:'',
          xydm:'',
        }
    }
  },
  created(){
      this.getBusinessInfo()
  },
  methods:{
    // 请求企业信息
    async getBusinessInfo(){
      // 从sessionStorage里取出存的token
     const tokenStr = window.sessionStorage.getItem('token')
     const {data:res} = await axios.post('BusinessInfo',{token:tokenStr})
    // console.log(res.data)
    this.businessInfo=res.data
    },
    // 提交企业信息
    async submitqyInfo(){
        // this.$message.success('修改成功')
         // this.$message.success('修改成功')
      this.businessInfo.token = window.sessionStorage.getItem('token')
      const {data:res}= await this.$http.post(
        'DoEditBusinessInfo',
        this.businessInfo
      )
      if(res.code == 0){
        this.$message.success('修改企业信息成功')
      }else{
        this.$message.error('修改企业信息失败')
      }
      console.log(res)
    }
  }
}
</script>

<style scoped lang="less">
.el-input .is-disabled .el-input__inner{
  color: #000!important;
}
.title {
  font-size: 30px;
  font-weight: 700;
  color: rgb(84, 92, 100);
  text-align: center;
  text-shadow: 2px 2px #fff;
  margin-bottom: 50px;
  margin-left: 50px;
  width: 100%;
  box-sizing: border-box;
}
.leftcard{
  width: 50%;
  display: inline-block;
  .formqy{
    width: 80%;
    margin: 0 auto;
  }
}
.qyForm {
  // width: 550px;
  margin: 20px auto;
  text-align: center;
  .formqy .el-input .is-disabled .el-input__inner{
    color: #000000!important;
}
}
.btns {
  text-align: center;
  .el-button {
    width: 100px;
  }
}
.rightarea{
    display: inline-block;
    width: 49%;
    text-align: center;
    vertical-align: top;
}
.demonstration{
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
}
</style>