<template>
  <div>企业用户查询组件</div>
</template>

<script>
export default {}
</script>

<style scoped lang="less">
</style>

