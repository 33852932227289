<template>
  <div>检测信息手动上传组件</div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">

</style>

