<template>
  <div>投入品采购组件</div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">

</style>

