<template>
    <div>
          <van-nav-bar title="AIOT+互联网金寨茶产业区块链试点项目" /> 
          <van-image width="100%" height="200" :src="showInfo.picname" />
          <div class="title">企业简介</div>
            <div class="des">
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {{this.showInfo.dwjj}}
              </p>
          </div>
          <div class="title">产品信息</div>
          <div class="des">
              <van-cell-group>
  <van-cell title="产品名称" :value="showInfo.cpmc" />
  <van-cell title="生产企业" :value="showInfo.qymc" />
  <van-cell title="产地名称" :value="showInfo.chandi" />
  <van-cell title="保质期" :value="showInfo.bzq+'天'" />
  <van-cell title="生产日期" :value="showInfo.rq" />
  <van-cell title="包装规格" :value="showInfo.cpgg" />
  <van-cell title="企业电话" :value="showInfo.fzrtel" />
</van-cell-group>
          </div>
            <div class="title">产品简介</div>
            <div class="des">
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {{this.showInfo.ms}}
              </p>
          </div>
            <div class="title">农事作业查询</div>
            <div class="des">
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  暂无信息
              </p>
          </div>
          <div class="footer"></div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                sym:'',
                showInfo:{
                }
            }
        },
        created(){
            // const getRequest =this
                this.getRequest()
        },
        methods:{
            async getRequest(){
             const url = window.location.href
              const  split_url = url.split('/')
            //   console.log(split_url)
              const symNum = split_url[5]
                this.sym = symNum
                const {data:res} =  await this.$http.post(
                    'ShowInformation',
                    {sym:this.sym}
                )
                // console.log(res)
                console.log(res.code)
                if (res.code === -1){
                        this.$router.push('/symReset')
                }else if(res.code === 0){
                    this.showInfo = res.data
                    console.log(this.showInfo)
                }
                
            },
        }
    }
</script>

<style lang="less" scoped>
    .title{
        text-align: center;
        background-color: #259b1f;
        width: 60%;
        height: 40px;
        margin: 0 auto;
        border-radius: 40px;
        line-height: 40px;
        color: #ffffff;
    }
    .des{
        width: 80%;
        margin: 5px auto;
    }
</style>