<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>


export default {
 
  
}
</script>

<style>
#app {
  height: 100%;
}
ul{
  list-style: none;
}
</style>
